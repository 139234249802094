export default {
  'login.tenant': 'Tenant',
  'login.account': 'Account',
  'login.account.placeholder': 'Mobile or Email',
  'login.password': 'Password',
  'login.code': 'Code',
  'login.btn': 'Login',
  'login.forget': 'Forget',
  'login.copyright':
    'Copyright 2021 深圳易思智科技有限公司 All Rights Reserved',
};
