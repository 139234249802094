import antdLocale from 'ant-design-vue/es/locale/en_US';
import momentLocale from 'moment/locale/eu';
import common from './common';
import login from './login';

const components = {
  momentName: 'eu',
  momentLocale,
  antdLocale,
};

export default {
  message: '-',
  ...components,
  ...common,
  ...login,
};
